<template>
  <div>
    <Header />

    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <!--<h2 class="HeaderTitulo1 TituloCor1 BackgroundColor2">VISÃO SIMPLES</h2>-->

          <div class="rowBlock">
            <h1 class="TituloCor1 lime">
              Lentes<br />
              Progressivas
            </h1>

            <h1 class="TituloCor1 lime Hide" id="TituloProgressivas_1">
              Premium
            </h1>
            <h1 class="TituloCor1 lime Hide" id="TituloProgressivas_2">
              intermediárias
            </h1>
            <h1 class="TituloCor1 lime Hide" id="TituloProgressivas_3">
              Básicas
            </h1>
            <h1 class="TituloCor1 lime Hide" id="TituloProgressivas_4">
              Convencionais
            </h1>

            <!--<h2 class="HeaderTitulo1 TituloCor1 BackgroundColor2 Hide" id="TituloVisaoSimples"></h2>-->

            <a
              @click="Hide_Info()"
              href="javascript:void(0)"
              class="BtnFechaBeneficios"
              id="BtnFechaBeneficios"
            >
              <img
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>

          <div class="DivEscolhas">
            <p class="MediumTxt White">Selecione uma categoria:</p>

            <div
              class="row top10 Btns_ShowProgressivas_Premium"
              id="Btns_ShowProgressivas_Premium"
            >
              <a
                @click="Premium_Button()"
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2"
                id="Mostra_Premium"
                >PROGRESSIVAS PREMIUM</a
              >
            </div>

            <div
              class="row Btns_ShowProgressivas_Intermediaria"
              id="Btns_ShowProgressivas_Intermediaria"
            >
              <a
                @click="Intermediary_Button()"
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2"
                id="Mostra_Intermediarias"
                >PROGRESSIVAS INTERMEDIÁRIAS</a
              >
            </div>

            <div
              class="row Btns_ShowProgressivas_Basica"
              id="Btns_ShowProgressivas_Basica"
            >
              <a
                @click="Basic_Button()"
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2"
                id="Mostra_Basicas"
                >PROGRESSIVAS BÁSICAS</a
              >
            </div>

            <div
              class="row Btns_ShowProgressivas_Convencionais"
              id="Btns_ShowProgressivas_Convencionais"
            >
              <a
                @click="Conventional_Button()"
                href="javascript:void(0)"
                class="BtnEmLinha1 BackgroundColor2 TextoBtnCor2"
                id="Mostra_Convencionais"
                >PROGRESSIVAS CONVENCIONAIS</a
              >
            </div>

            <div class="row">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1">Lentes Progressivas</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
 <carousel
              :autoplay="false"
              :loop="false"
              :nav="true"
              :dots= "true"
              :responsive="{0:{items:1,nav:false},450:{items:1,nav:true},1000:{items:1,nav:true}}"       
            >            
              <div class="item">
                <div class="row">
                  <img
                    src="./images/Progressivas/Banner_Progressivas_1.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    A lente progressiva ou multifocal é a evolução da lente
                    bifocal, na qual existia duas correções distintas, uma para
                    longe e outra para perto, ficando uma divisão visível que
                    marcava a lente, que a tornava estéticamente desagradável e
                    rudimentar.<br />
                    É uma evolução comparável a mudança que aconteceu com várias
                    maneiras como vemos e fazemos as coisas nos dias de hoje
                    como: como pagamos nossas contas, a imagem em nossa
                    televisão, a fotografia e a forma como ouvimos música.
                  </p>
                </div>
              </div>

              <div class="item ">
                <img
                  src="./images/Progressivas/Banner_Progressivas_2.png"
                  class="img-fluid"
                />

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    A Lentes progressivas freeform são produzidas pelo moderno
                    sistema digital free form, tecnologia totalmente digital e
                    computadorizada que confecciona lentes com correções ópticas
                    de alta precisão, pois são desenhadas ponto a ponto em sua
                    superfície interna, aproximando a correção óptica aos olhos
                    e possibilitando o aumento em até 30% de amplitude para
                    todos os campos de visão.
                  </p>
                </div>
              </div>

              <div class="item ">
                <img
                  src="./images/Progressivas/Banner_Progressivas_3.png"
                  class="img-fluid"
                />

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    Com base na prescrição médica e os dados do usuário obtidos
                    no momento da compra, O processo digital freeform produz em
                    uma só superfície uma combinação da dioptria para correção
                    da visão para longe e outra para perto, proporcionando um
                    corredor entre as duas que será usada para a visão
                    intermediária.<br />
                    Sem divisão visível, apenas em progressão, e quanto mais
                    moderna a tecnologia usada na produção, maior será a
                    amplitude dos campos de visão e menor serão as distorções
                    periféricas (em razão da topografia produzida na lente).
                  </p>
                </div>
              </div>

              <div class="item ">
                <img
                  src="./images/Progressivas/Banner_Progressivas_4.png"
                  class="img-fluid"
                />

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    A tecnologia digital freeform possibilita a personalização
                    das lentes, pois consideram várias informações que podem
                    auxiliar no processo de produção:<br />
                    Receita prescrita pelo oftalmologista + Medidas dos olhos +
                    Posição dos olhos + Medidas da armação escolhida + Anatomia
                    do rosto + Estilo de vida do usuário.<br />
                    Desta forma são feitas lentes exclusivas e customizadas,
                    auxiliando e maximizando a adaptação à lentes multifocais.
                  </p>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Freeform.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">TECNOLOGIA DIGITAL FREEFORM</h5>
                      <p class="White Opacidade5 top5">
                        São produzidas sob a demanda do usuário, isso é, não são
                        lentes prontas e sim lentes que vão ser confeccionadas
                        exclusivamente para quem as adquiriu.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Olhos.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">MAIOR AMPLITUDE VISUAL</h5>
                      <p class="White Opacidade5 top5">
                        Apesar das lentes serem extremamente finas, em média com
                        3mm de espessura, este recurso proporciona um enorme
                        ganho na amplitude dos campos de visão.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/Progressivas/Progressivas_CamposVisao_Convencionais.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">
                        PRODUZIDAS PELOS MÉTODOS CONVENCIONAIS
                      </h5>
                      <p class="White Opacidade5 top5">
                        Campos de visão limitados ao centro da lente, com
                        grandes distorções laterais que causam aberrações
                        visuais e, consequentemente, sensação de flutuação e
                        vertigem.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/Progressivas/Progressivas_CamposVisao_Freeform.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">
                        PRODUZIDAS PELO PROCESSO FREEFORM
                      </h5>
                      <p class="White Opacidade5 top5">
                        Campos de visão mais amplos para todas as distâncias e
                        redução acentuada das distorções laterais, que auxilia e
                        maximiza a adaptação ao uso de lentes multifocais.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Perto_1.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">
                        PRODUZIDAS PELOS MÉTODOS CONVENCIONAIS
                      </h5>
                      <p class="White Opacidade5 top5">
                        O centro óptico das lentes fica limitado, fazendo com
                        que os olhos se movimentem muito mais para encontrar o
                        ponto mais confortável para leitura.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Perto_2.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">
                        PRODUZIDAS PELO PROCESSO FREEFORM
                      </h5>
                      <p class="White Opacidade5 top5">
                        O ponto central das lentes é muito mais amplos, captando
                        muito mais imagens no ponto confortável de visão,
                        diminuindo o movimento ocular.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Longe_1.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">
                        PRODUZIDAS PELOS MÉTODOS CONVENCIONAIS
                      </h5>
                      <p class="White Opacidade5 top10">
                        Como são lentes com combinações de curvas prontas, o
                        poder de correção fica concentrado numa parte pequena
                        das lentes.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Longe_2.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">
                        PRODUZIDAS PELO PROCESSO FREEFORM
                      </h5>
                      <p class="White Opacidade5 top10">
                        A produção FreeForm produz a correção sob medida e na
                        parte interna das lentes, aproximando o poder óptico aos
                        olhos e aumentando a amplitude da visão.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item ">
                <div class="row">
                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Livro_1.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">
                        PRODUZIDAS PELOS MÉTODOS CONVENCIONAIS
                      </h5>
                      <p class="White Opacidade5 top10">
                        As bordas das lentes apresentam distorções periféricas
                        que causam aberrações visuais, defeito que incomoda
                        principalmente ao subir escadas.
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <img
                      src="./images/VisaoSimples/VisaoSimples_Livro_2.png"
                      class="img-fluid"
                      alt="Freeform"
                    />

                    <div class="row text-center justify-content-center top30">
                      <h5 class="TituloCor1">
                        PRODUZIDAS PELO PROCESSO FREEFORM
                      </h5>
                      <p class="White Opacidade5 top10">
                        Como a produção das curvas é feita ponto-a-ponto, as
                        distorções são reduzidas ao máximo, proporcionando maior
                        conforto visual.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->

    <div class="Progressivas_Escolha" id="Progressivas_Escolha">
      <a
        @click="Distant_Button()"
        href="javascript:void(0)"
        class="BtnProgressivas ProgressivasEscolha_BtnActive"
        id="Chama_Longe"
      >
        <img src="./svg/Progressivas_Longe.svg" class="Icones" alt="Longe" />

        <h3>LONGE</h3>
      </a>

      <a
        @click="Near_Button()"
        href="javascript:void(0)"
        class="BtnProgressivas"
        id="Chama_Perto"
      >
        <img src="./svg/Progressivas_Perto.svg" class="Icones" alt="Perto" />

        <h3>PERTO</h3>
      </a>
    </div>

    <div
      class="VisaoSimples VisaoSimples_Convencional"
      id="Background_VisaoSimples_Convencional"
    ></div>

    <div class="Progressivas" id="Background_Progressivas">
      <ul id="scene" class="scene">
        <li class="layer" data-depth="1">
          <div
            class="Progressivas_Lentes Progressivas_Premium"
            id="Progressivas_Lentes"
          ></div>
        </li>
      </ul>
      <div class="Progressivas_Iphone" id="Progressivas_Iphone"></div>
      <div class="Progressivas_Blurred" id="Progressivas_Blurred"></div>
    </div>
  </div>
</template>

<script>
import {
  Show_lens,
  Near_Button,
  Distant_Button,
  Premium_Button,
  Intermediary_Button,
  Basic_Button,
  Conventional_Button,
} from '@/views/menu/js/new/Progressive_Script'

import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

import Header from '@/views/menu/components/header.vue'
import carousel from 'vue-owl-carousel'

export default {
  components: {
    Header,
    carousel
  },
  methods: {
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,
    Near_Button,
    Distant_Button,
    Premium_Button,
    Intermediary_Button,
    Basic_Button,
    Conventional_Button,
  },
  created() {
    Show_lens()
  },
}
</script>

<style scoped>
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import "./css/plugins/owl.carousel.min.css"; */
@import './css/plugins/owl.theme.default.min.css';
@import './css/Progressivas_Style.css';

.lime {
  color: #abd159;
}
.Hide {
  display: none;
}
</style>
