export function Show_lens() {
  setTimeout(function () {
    document.getElementById('Progressivas_Lentes').classList.add('Entra_Lentes')
  }, 1800)

  setTimeout(function () {
    document
      .getElementById('Progressivas_Blurred')
      .classList.add('Some_Blurred')
    document
      .getElementById('Progressivas_Escolha')
      .classList.add('Entra_Escolha')
  }, 2500)

  setTimeout(function () {
    document
      .getElementById('Btns_ShowProgressivas_Premium')
      .classList.add('DeixaInativo')
    document
      .getElementById('Btns_ShowProgressivas_Intermediaria')
      .classList.remove('DeixaInativo')
    document
      .getElementById('Btns_ShowProgressivas_Basica')
      .classList.remove('DeixaInativo')

    document.getElementById('Mostra_Premium').classList.add('BtnEmLinhaInativo')
    document
      .getElementById('Mostra_Intermediarias')
      .classList.remove('BtnEmLinhaInativo')
    document
      .getElementById('Mostra_Basicas')
      .classList.remove('BtnEmLinhaInativo')

    document.getElementById('TituloProgressivas').classList.remove('Hide')
  }, 2800)
}

export function Near_Button() {
  document.getElementById('Progressivas_Lentes').classList.add('Muda_Perto')
  document.getElementById('Progressivas_Iphone').classList.add('Entra_Iphone')
  document
    .getElementById('Background_Progressivas')
    .classList.add('Muda_Background_Perto')
  document
    .getElementById('Chama_Longe')
    .classList.remove('ProgressivasEscolha_BtnActive')
  document
    .getElementById('Chama_Perto')
    .classList.add('ProgressivasEscolha_BtnActive')
}

export function Distant_Button() {
  document.getElementById('Progressivas_Lentes').classList.remove('Muda_Perto')
  document
    .getElementById('Progressivas_Iphone')
    .classList.remove('Entra_Iphone')
  document
    .getElementById('Background_Progressivas')
    .classList.remove('Muda_Background_Perto')
  document
    .getElementById('Chama_Longe')
    .classList.add('ProgressivasEscolha_BtnActive')
  document
    .getElementById('Chama_Perto')
    .classList.remove('ProgressivasEscolha_BtnActive')
}

export function Premium_Button() {
  setTimeout(function () {
    document
      .getElementById('Progressivas_Blurred')
      .classList.add('Some_Blurred')
    document
      .getElementById('Progressivas_Escolha')
      .classList.add('Entra_Lentes')
  }, 300)

  document
    .getElementById('Btns_ShowProgressivas_Premium')
    .classList.add('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Intermediaria')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Basica')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Convencionais')
    .classList.remove('DeixaInativo')

  document.getElementById('Mostra_Premium').classList.add('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Intermediarias')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Basicas')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Convencionais')
    .classList.remove('BtnEmLinhaInativo')

  document.getElementById('TituloProgressivas_1').classList.remove('Hide')
  document.getElementById('TituloProgressivas_2').classList.add('Hide')
  document.getElementById('TituloProgressivas_3').classList.add('Hide')
  document.getElementById('TituloProgressivas_4').classList.add('Hide')

  document
    .getElementById('Progressivas_Lentes')
    .classList.add('Progressivas_Premium')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Intermediarias')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Basicas')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Convencionais')

  document
    .getElementById('Mostra_Premium')
    .classList.add('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Intermediarias')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Basicas')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Convencionais')
    .classList.remove('BtnMudaLentes_Inativo')
}

export function Intermediary_Button() {
  setTimeout(function () {
    document
      .getElementById('Progressivas_Blurred')
      .classList.add('Some_Blurred')
    document
      .getElementById('Progressivas_Escolha')
      .classList.add('Entra_Lentes')
  }, 300)

  document
    .getElementById('Btns_ShowProgressivas_Premium')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Intermediaria')
    .classList.add('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Basica')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Convencionais')
    .classList.remove('DeixaInativo')

  document
    .getElementById('Mostra_Premium')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Intermediarias')
    .classList.add('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Basicas')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Convencionais')
    .classList.remove('BtnEmLinhaInativo')

  document.getElementById('TituloProgressivas_1').classList.add('Hide')
  document.getElementById('TituloProgressivas_2').classList.remove('Hide')
  document.getElementById('TituloProgressivas_3').classList.add('Hide')
  document.getElementById('TituloProgressivas_4').classList.add('Hide')

  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Premium')
  document
    .getElementById('Progressivas_Lentes')
    .classList.add('Progressivas_Intermediarias')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Basicas')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Convencionais')

  document
    .getElementById('Mostra_Premium')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Intermediarias')
    .classList.add('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Basicas')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Convencionais')
    .classList.remove('BtnMudaLentes_Inativo')
}

export function Basic_Button() {
  setTimeout(function () {
    document
      .getElementById('Progressivas_Blurred')
      .classList.add('Some_Blurred')
    document
      .getElementById('Progressivas_Escolha')
      .classList.add('Entra_Lentes')
  }, 300)

  document
    .getElementById('Btns_ShowProgressivas_Premium')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Intermediaria')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Basica')
    .classList.add('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Convencionais')
    .classList.remove('DeixaInativo')

  document
    .getElementById('Mostra_Premium')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Intermediarias')
    .classList.remove('BtnEmLinhaInativo')
  document.getElementById('Mostra_Basicas').classList.add('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Convencionais')
    .classList.remove('BtnEmLinhaInativo')

  document.getElementById('TituloProgressivas_1').classList.add('Hide')
  document.getElementById('TituloProgressivas_2').classList.add('Hide')
  document.getElementById('TituloProgressivas_3').classList.remove('Hide')
  document.getElementById('TituloProgressivas_4').classList.add('Hide')

  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Premium')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Intermediarias')
  document
    .getElementById('Progressivas_Lentes')
    .classList.add('Progressivas_Basicas')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Convencionais')

  document
    .getElementById('Mostra_Premium')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Intermediarias')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Basicas')
    .classList.add('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Convencionais')
    .classList.remove('BtnMudaLentes_Inativo')
}

export function Conventional_Button() {
  setTimeout(function () {
    document
      .getElementById('Progressivas_Blurred')
      .classList.add('Some_Blurred')
    document
      .getElementById('Progressivas_Escolha')
      .classList.add('Entra_Lentes')
  }, 300)

  document
    .getElementById('Btns_ShowProgressivas_Premium')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Intermediaria')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Basica')
    .classList.remove('DeixaInativo')
  document
    .getElementById('Btns_ShowProgressivas_Convencionais')
    .classList.add('DeixaInativo')

  document
    .getElementById('Mostra_Premium')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Intermediarias')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Basicas')
    .classList.remove('BtnEmLinhaInativo')
  document
    .getElementById('Mostra_Convencionais')
    .classList.add('BtnEmLinhaInativo')

  document.getElementById('TituloProgressivas_1').classList.add('Hide')
  document.getElementById('TituloProgressivas_2').classList.add('Hide')
  document.getElementById('TituloProgressivas_3').classList.add('Hide')
  document.getElementById('TituloProgressivas_4').classList.remove('Hide')

  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Premium')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Intermediarias')
  document
    .getElementById('Progressivas_Lentes')
    .classList.remove('Progressivas_Basicas')
  document
    .getElementById('Progressivas_Lentes')
    .classList.add('Progressivas_Convencionais')

  document
    .getElementById('Mostra_Premium')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Intermediarias')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Basicas')
    .classList.remove('BtnMudaLentes_Inativo')
  document
    .getElementById('Mostra_Convencionais')
    .classList.add('BtnMudaLentes_Inativo')
}
